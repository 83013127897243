<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-form ref="form" :model="form" label-width="0px">
          <el-col :span="6">
            <el-form-item label="赛事" prop="matchId" label-width="60px">
              <el-select v-model="form.matchId" clearable="">
                <el-option v-for="item in competitionList" :key="item.matchId" :label="item.matchName" :value="item.matchId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否使用" prop="useFlag" label-width="120px">
              <el-select v-model="form.useFlag" clearable="">
                <el-option v-for="(item, index) in DROPDOWNBOX.sys_yes_no" :key="`${item.label}_${index}`" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="12" style="text-align:right">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        @sort-change='sort_post_change'
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="index"
          align="center"
          label="序号"
          fixed="left"
          width="55">
        </el-table-column>
        <el-table-column
					type="selection"
          align="center"
          fixed="left"
					width="55">
				</el-table-column>
				<el-table-column
					prop="code"
          min-width="180"
          align="center"
          sortable='custom'
          show-overflow-tooltip
					label="邀请码">
				</el-table-column>
				<el-table-column
					prop="matchName"
          align="center"
          sortable='custom'
          min-width="168"
					label="绑定赛事">
				</el-table-column>
        <el-table-column
					prop="createBy"
          align="center"
          sortable='custom'
          min-width="120"
					label="创建人">
				</el-table-column>
				<el-table-column
					prop="createTime"
          align="center"
          min-width="200"
          sortable='custom'
					label="创建时间">
				</el-table-column>
        <el-table-column
					prop="useFlag"
          align="center"
          min-width="115"
          sortable='custom'
          :formatter="ynFormatter"
					label="是否使用">
				</el-table-column>
				<el-table-column
					prop="matchTeamName"
          min-width="110"
          align="center"
					label="绑定团队">
				</el-table-column>
        <el-table-column
          align="center"
          min-width="120px"
					prop=""
					label="分享二维码">
          <template slot-scope="scope">
            <a href="#" @click="changeAddDialog(true, scope.row.qrCodePath)">分享</a>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-content">
      <div>
        <el-button class="default-button" @click="generateCode">生成邀请码</el-button>
        <el-button class="default-button" :disabled="selectedArr.length < 1" @click="openDeleteDialog(selectedArr.map(item => item.id).join(','), 1)">{{$t('delete')}}</el-button>
      </div>
    <!-- </div>
    <div class="page-container"> -->
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :erweimaPath="erweimaPath" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
  </div>
</template>
<script>
import IndexClass from './indexClass'
import mixin from '@/mixins/index'
import apis from '@/apis'
import { mapState } from "vuex";
import addDialog from "./dialog/erweima.vue";
import confirmDialog from '@/components/confirmDialog.vue'

export default {
  mixins: [mixin],
  components: { addDialog, confirmDialog },
  data() {
    return {
      form: new IndexClass('form'), // 实例化一个表单的变量
      status: '',
      tableData: [],
      competitionList: [], //赛事下拉列表
      erweimaPath: '',
      addDialog: {
        visible: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
    }
  },
  mounted() {
  },
  activated() {
    this.$http.get(`${apis.getCompetition}${this.venueId}/invitedMatches`).then((res) => {
      if (res.data.code === 0) {
        this.competitionList = res.data.data
      }
    })
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", 'venueId']),
  },
  methods: {
    // 表格选中
    handleSelectionChange(arr) {
      this.selectedArr = arr
    },
    // 清空按钮
    cleanForm() {
      this.$refs.form.resetFields()
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    // 查询
    searchData() {
      const formData = { ...this.form }
      this.$http.post(apis.invatationList, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    // 是否使用自定义转换
    ynFormatter(row, column, cellValue) {
      let name = ''
      if (cellValue === 'Y') {
        name = '是'
      } else if (cellValue === 'N') {
        name = '否'
      }
      return name
    },
    // 新增修改
    changeAddDialog(dialogStatus, erweimaPath = '') {
      if (dialogStatus) {
        this.erweimaPath = erweimaPath
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type
      this.confirmDialog.data.ids = id
      this.changeConfirmDialog(true, type)
    },
    /*
      生成邀请码
    */
   generateCode() {
      if(this.form.matchId){
        this.$http.post(apis.generatorCode, {matchId: this.form.matchId}).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("生成成功!")
            this.searchData()
          }
        })
      } else {
        this.$message.warning("请先选择需要邀请码的赛事")
      }
      
   },
    /**
     * 删除
     */
    sureConfirmDialog() {
      this.$http.delete(`${apis.deleteCode}?ids=${this.confirmDialog.data.ids}`).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("删除成功!")
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../assets/styles/mixin.less";
.wrap {
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}
</style>
